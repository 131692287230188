import Vue from "vue";
import App from "./App.vue"
import router from './router'
import store from "./store";
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import 'amfe-flexible'; 
import _ from 'lodash'
import { Message } from 'element-ui';
Vue.prototype.$message = Message
Vue.prototype._ = _
import {Row,Col,Select,Option,Backtop,Input,Checkbox,Upload,Button,Card } from 'element-ui';
Vue.use(Row)
Vue.use(Col)
Vue.use(Select)
Vue.use(Option)
Vue.use(Backtop)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Button)
Vue.use(Card)
Vue.config.productionTip = true;
//创建vue实例
new Vue({
    el:"#app",
    router,
    store,
    render: h=>h(App),
    beforeCreate(){
        Vue.prototype.$sub = this
    }
})