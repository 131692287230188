<template>
  <div class="footer_main_box">
    <div class="foot_content_box">
      <div class="foot_tip">© 2024 -Hisongame. All rights reserved.</div>
      <div class="Privacy">
        <span @click="toPrivacy()">Privacy policy</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"myFooter",
  data(){
    return{

    }
  },
  methods:{
    toPrivacy(){
      location.href = "https://www.hisongame.com/Privacy.html"
    }
  }
}
</script>

<style>
.footer_main_box{
  width: 100%;
  padding: 14px 0;
  background-color: #F1F1F1;
  display: flex;
  flex-direction: column;
}
.foot_content_box{
  width: 100%;
}
.foot_content_box>.foot_tip{
  text-align: center;
  color: gray;
  font-size: 9px;
}
.Privacy{
  color: gray;
  display: flex;
  justify-content: flex-end;
}
.Privacy>span{
  border-bottom: 1px solid gray;
  margin-right: 0.8rem;
  cursor: pointer;
  font-size: 0.082rem;
}
.Privacy>span:hover{
  border-bottom: 1px solid #409eff;
  color: #409eff;
}
/* #409eff */
</style>