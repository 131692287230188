<template>
  <div class="main_box">
    <div class="header_box">
      <div class="header_top_box">
        <img @click="toPath('home')" src="../static/home/headertop.png" alt="">
      </div>
      <div class="menu_main_box">
        <div :class="curRouteName === 'home'?'active':''" class="opn_item" @click="toPath('home')">about Us<span></span></div>
        <div :class="curRouteName === 'aboutUs'?'active':''" class="opn_item" @click="toPath('aboutUs')">Contact Us<span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myHeader",
  data() {
    return {
      menu: false,
      isMobile: false,
      curRouteName:"home",
      timer: null,
    };
  },
  mounted() {
    this.curRouteName = this.$router.history.current.name
  },
  computed: {
    size() {
      return this.$store.state.size;
    },
  },
  watch: {
    size() {
      if (this.size < 560) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.menu = false
      }
    },
    $route(){
      this.curRouteName = this.$router.history.current.name
    }
  },
  methods: {
    toPath(name){
      this.$router.push({name})
    }
  },
};
</script>

<style scoped>
.main_box {
  position: relative;
}
.header_box {
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  /* background-color: aqua; */
}
.header_top_box{
  position: absolute;
  top: 0;
  left: -10px;
}
.header_top_box>img{
  width: 180px;
  display: block;
}
.header_box>div{
  margin: 0 10px;
}
.menu_main_box{
  padding-left: 200px;
  display: flex;
}
.opn_item {
  position: relative;
  font-size: 9px;
  font-weight: 600;
  margin: 0 10px;
  color: #7f7f7f;
  line-height: 24px;
  cursor: pointer;
}
.active{
  color: #f18101;
}

.active>span{
  content: "";
  width: 100%;
  height: 1px;
  background-color: #f18101;
  position: absolute;
  top: 50%;
  left: 0;
}
.opn_item:hover {
  color: #f18101;
}
.opn_item:before,
.opn_item:after {
  content: "";
  width: 0;
  height: 1px;
  background-color: #f18101;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.4s;
}

.opn_item:hover:before {
  width: 50%;
  left: 0px;
}

.opn_item:hover:after {
  width: 50%;
  right: 0px;
}
</style>