import Vue from "vue";
import Router from "vue-router"
import home from "@/views/index.vue"
import aboutUs from "@/views/aboutUs.vue"
Vue.use(Router)
const myPush = Router.prototype.push
let router = new Router({
    routes:[{
        name:"home",
        path:"/home",
        component:home
    },{
        path:'/',
        component:home
    },{
        //关于我们
        name:"aboutUs",
        path:"/aboutUs",
        component:aboutUs
    }],
    scrollBehavior(to, from, savedPosition) {
        return { y: 0 }
    }
})
Router.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        myPush.call(this, location)
    } else {
        myPush.call(this, location, () => { }, () => { })
    }
}
export default router