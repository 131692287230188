<template>
  <div>
    <!-- 轮播图-->
    <mySwiper></mySwiper>
    <div class="center_tit">
      <span>OUR GAMES</span>
      <div class="center_tit_tip">Hison Game is a game app developed specifically for the Indian market. It includes the most classic Indian game Teen Patti, as well as the most popular casual skill game rummy. Other popular games are still being developed, so stay tuned.</div>
      <div class="center_tit_tip">The game is fun and interesting, with stable and smooth performance. If you have any suggestions, please feel free to contact the team at any time. Thank you！</div>
    </div>
    <div class="center_main_box">
      <div class="center_item_box">
        <img src="../static/home/item1.png" alt="">
        <div class="item_tit">Teen Patti</div>
      </div>
      <div class="center_item_box">
        <img src="../static/home/item3.png" alt="">
        <div class="item_tit">Rummy</div>
      </div>
      <div class="center_item_box">
        <img src="../static/home/item2.png" alt="">
        <div class="item_tit">coming soon</div>
      </div>
      <!-- <div class="center_item_box">
        <img src="../static/home/item4.png" alt="">
        <div class="item_tit">Blackjack21</div>
      </div>
      <div class="center_item_box">
        <img src="../static/home/item5.png" alt="">
        <div class="item_tit">Domino</div>
      </div>
      <div class="center_item_box">
        
      </div> -->
    </div>
  </div>
</template>

<script>
import mySwiper from "@/component/mySwiper.vue"
export default {
  components: {mySwiper},
  name:"home",
  data() {
    return {
      timer: null,
      bannerH: 1200, //轮播图高度
    };
  },
  mounted() {
    // this.getBannerH();
  },
  computed: {
    size() {
      return this.$store.state.size;
    },
  },
  methods: {
    getBannerH() {
      this.timer = setTimeout(() => {
        this.bannerH = this.$refs.banner.clientHeight;
      }, 100);
    },
    download(url){
      window.location.href = url
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style scoped>
.banner_box > img {
  width: 100%;
}
.center_main_box{
  width: 100%;
  padding: 20px 0;
  /* background-color: aqua; */
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}
.center_item_box{
  width: 30%;
  height: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.item_tit{
  text-align: center;
  color: #FF6100;
  font-size: 10px;
  font-weight: 550;
  letter-spacing: 1px;
}
.center_item_box>img{
  width: 80%;
  display: flex;
}
.center_item_box>img:hover{
  width: 85%;
  transition: width 0.4s ease-out;
}
.center_tit{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}
.center_tit>span{
  font-size: 28px;
  font-weight: 600;
  color: #FF6100;
  /* letter-spacing: 2px; */
  margin-bottom: 10px;
}
.center_tit_tip{
  width: 90%;
  text-align: start;
  color: #FF6100;
  line-height: 12px;
  font-size: 8px;
}
</style>