<template>
  <div class="main_box">
    <div class="content_box">
      <div class="about_me_left_box">
        <el-card style="border-radius:20px;">
          <div class="card_box">
            <span class="text1">Customer Service:</span>
            <span class="text2">cs@hisongame.com</span>
          </div>
        </el-card>
        <div class="face_book_box">
          <img @click="toFace()" src="../static/aboutUs/ic2.png" alt="">
        </div>
      </div>
      <div class="about_me_right_box">
        <img src="../static/aboutUs/ic1.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data() {
    return {};
  },
  methods:{
    toFace(){
      location.href = "https://www.facebook.com/profile.php?id=61560490791158"
    }
  }
};
</script>

<style scoped>
.main_box{
  width: 100%;
  padding-bottom: 30px;
  /* height: 82vh; */
}
.content_box{
  margin-top: 20px;
  width: 100%;
  display: flex;
}
.about_me_left_box{
  width: 54%;
  padding:0 10px;
  /* background-color: red; */
}
.about_me_right_box{
  width: 40%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.about_me_right_box>img{
  width: 94%;
  display: block;
  border-radius: 23px;
  box-shadow: 2px 2px 10px 2px hsl(225, 4%, 82%);
}
.about_me_right_box>img:hover{
  width: 99%;
  transition: width 0.4s ease-out;
}
.card_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f18101;
  height: 0.62rem;
}
.card_box:hover{
  height: 0.74rem;
  transition: height 0.4s ease-out;
}
.face_book_box{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.face_book_box>img{
  width: 27%;
  cursor: pointer;
}
.face_book_box>img:hover{
  transition: width 0.4s ease-out;
  width: 30%;
}
.card_box>.text1{
  font-size: 10px;
}
.card_box>.text2{
  margin-top: 2px;
  font-size: 20px;
  font-weight: 600;
}
</style>